<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                $t("PRODUCT_PAGE_META.NOT_FOUND")
            }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div
                    class="card-title align-items-center justify-content-center flex-column"
                >
                    <h3
                        v-if="$route.params.id"
                        class="card-label font-weight-bolder text-dark"
                    >
                        {{
                            edit_mode
                                ? add_new
                                    ? $t("PRODUCT_PAGE_META.NEW")
                                    : $t("PRODUCT_PAGE_META.EDIT")
                                : $t("PRODUCT_PAGE_META.VIEW")
                        }}
                    </h3>
                    <h3 v-else class="card-label font-weight-bolder text-dark">
                        {{ edit_mode ? $t("ABOUT.EDIT") : $t("ABOUT.ABOUT") }}
                    </h3>
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                        v-on:click="save()"
                        class="btn btn-primary px-15 mr-2"
                        id="product_page_meta_save"
                        >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                        >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                        >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="product_page_meta_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{ $t("PRODUCT_PAGE_META.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                        ref="name"
                                        name="name"
                                        class="form-control form-control-md form-control-solid"
                                        type="text"
                                        v-model="product_page.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row" v-if="!edit_mode">
                                <label
                                    class="col-xl-3 col-lg-3 col-form-label text-right"
                                    :class="edit_mode ? 'required' : ''"
                                    >{{
                                        $t("PRODUCT_PAGE_META.PRODUCT_PAGE")
                                    }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <div class="d-flex align-items-center">
                                        <router-link
                                            :to="{
                                                name: 'product_page',
                                                params: {
                                                    id: this.$route.params.id,
                                                    edit: true,
                                                },
                                            }"
                                            style="pointer-events: all"
                                            class="d-flex align-items-center btn btn-light btn-icon btn-hover-primary"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/Communication/Write.svg"
                                                    :title="$t('EDIT')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
        <div>
            <!-- bestehende Elemente -->
            <!-- Beginn: Card für Produktrelationen -->
            <div v-if="!not_found" class="card card-custom mt-5">
                <div class="card-header py-5">
                    <div
                        class="card-title align-items-start justify-content-center flex-column"
                    >
                        <h3 class="card-label font-weight-bolder text-dark">
                            {{ $t("PRODUCT_PAGE_META.PRODUCT_RELATIONS") }}
                        </h3>
                    </div>
                </div>
                <div class="card-body">
                    <div v-if="product_page.products.length > 0">
                        <div class="table-responsive">
                            <table
                                class="table table-head-custom table-vertical-center"
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            {{ $t("PRODUCT_PAGE_META.NAME") }}
                                        </th>
                                        <th class="pr-0 text-right">
                                            {{ $t("ACTIONS") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="relation in product_page.products"
                                        :key="relation.id"
                                    >
                                        <td>{{ relation.product_name }}</td>
                                        <td class="pr-0 text-right">
                                            <a
                                                v-on:click="
                                                    removeRelation(
                                                        relation.product_id
                                                    )
                                                "
                                                class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            >
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary"
                                                >
                                                    <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                                    <inline-svg
                                                        src="media/svg/icons/General/Trash.svg"
                                                        :title="$t('DELETE')"
                                                    ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-else>
                        <p class="text-center">
                            {{ $t("PRODUCT_PAGE_META.NO_PRODUCTS") }}
                        </p>
                    </div>
                    <div class="form-group mt-5">
                        <div class="dropdown">
                            <b-dropdown variant="primary">
                                <template #button-content>
                                    {{
                                        $t(
                                            "PRODUCT_PAGE_META.ADD_PRODUCT_BUTTON"
                                        )
                                    }}
                                </template>
                                <b-dropdown-form
                                    v-for="product in products"
                                    :key="product.id"
                                >
                                    <input
                                        type="checkbox"
                                        :value="product.id"
                                        v-model="new_relation_product_ids"
                                    />
                                    {{ product.name }}
                                </b-dropdown-form>
                                <b-dropdown-text style="min-width: 280px;">
                                    <a
                                        v-on:click="addRelation()"
                                        id="add_relation_button"
                                        class="btn btn-primary px-10 mt-2 w-100"
                                    >
                                        {{
                                            $t(
                                                "PRODUCT_PAGE_META.ADD_PRODUCT_BUTTON"
                                            )
                                        }}
                                    </a>
                                </b-dropdown-text>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service"
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger"
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap"
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core"
import KTUtil from "@/assets/js/components/util"
import {
    SET_BREADCRUMB,
    ADD_BREADCRUMB,
} from "@/core/services/store/breadcrumbs.module"

export default {
    name: "product_page_meta",
    data() {
        return {
            add_new: false,
            edit_mode: false,
            not_found: false,
            original_product_page: {},
            product_page: {
                id: this.$route.params.id,
                name: "",
                isDefault: false,
                products: [],
            },
            products: [],
            new_relation_product_ids: [],
            dropdownOpen: true,
        }
    },
    mounted() {
        if (this.$route.params.id == "new") {
            this.$store.dispatch(ADD_BREADCRUMB, [
                { title: this.$t("PRODUCT_PAGE_META.NEW") },
            ])
            this.add_new = true
            this.edit_mode = true
        } else {
            if (this.$route.params.id !== "new") {
                ApiService.get("product_page_meta/" + this.$route.params.id)
                    .then((data) => {
                        this.product_page = data.data
                        this.original_product_page = data.data
                        this.$store.dispatch(SET_BREADCRUMB, [
                            {
                                title: this.$t("PRODUCT_PAGES.TITLE"),
                                route: `/product_pages`,
                            },
                            {
                                title: this.product_page.name,
                                route: `/product_pages/${this.$route.params.id}`,
                            },
                        ])
                    })
                    .catch(() => {
                        this.not_found = true
                        this.$store.dispatch(SET_BREADCRUMB, [])
                    })
                ApiService.get(
                    "product_page_meta/" + this.product_page.id + "/products"
                )
                    .then(({ data }) => {
                        this.products = data.sort((a, b) => {
                            return a.name.localeCompare(b.name)
                        })
                    })
                    .catch(() => {
                        //
                    })
            } else {
                this.not_found = true
            }
        }

        this.fv = formValidation(KTUtil.getById("product_page_meta_form"), {
            fields: {
                name: {
                    validators: {
                        notEmpty: {
                            message: this.$t("VALIDATION.REQUIRED", {
                                name: "Bezeichnung",
                            }),
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        })
    },
    methods: {
        edit() {
            this.edit_mode = true
        },
        cancel() {
            if (this.add_new) {
                this.$router.push({ name: "product_pages" })
            } else {
                this.product_page = JSON.parse(
                    JSON.stringify(this.original_product_page)
                )
                this.fv.resetForm(true)
                this.edit_mode = false
            }
        },
        save() {
            this.fv.validate().then((status) => {
                if (status === "Valid") {
                    KTUtil.getById("product_page_meta_save").classList.add(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    )

                    if (this.add_new) {
                        ApiService.post("product_page_meta", this.product_page)
                            .then((data) => {
                                this.$router.push({
                                    name: "product_page_meta",
                                    params: { id: data.data.id },
                                })
                                KTUtil.getById("brand_save").classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                            .catch(() => {
                                KTUtil.getById(
                                    "product_page_meta_save"
                                ).classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    } else {
                        ApiService.put(
                            "product_page_meta/" + this.product_page.id,
                            this.product_page
                        )
                            .then(() => {
                                this.edit_mode = false
                                KTUtil.getById(
                                    "product_page_meta_save"
                                ).classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                            .catch(() => {
                                KTUtil.getById(
                                    "product_page_meta_save"
                                ).classList.remove(
                                    "spinner",
                                    "spinner-light",
                                    "spinner-right"
                                )
                            })
                    }
                }
            })
        },
        addRelation() {
            if (this.new_relation_product_id !== "") {
                KTUtil.getById("add_relation_button").classList.add(
                    "spinner",
                    "spinner-light",
                    "spinner-right"
                )
                ApiService.post(
                    "product_page_meta/" + this.product_page.id + "/relation",
                    {
                        product_ids: this.new_relation_product_ids,
                    }
                )
                    .then(() => {
                        this.new_relation_product_ids.forEach((id) => {
                            const product = this.products.find(
                                (product) => product.id === id
                            )
                            this.product_page.products.push({
                                product_id: id,
                                product_name: product.name,
                            })
                        })
                        this.products = this.products.filter(
                            (product) =>
                                !this.new_relation_product_ids.includes(
                                    product.id
                                )
                        )
                        this.new_relation_product_ids = []
                        KTUtil.getById("add_relation_button").classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        )
                    })
                    .catch(() => {
                        KTUtil.getById("add_relation_button").classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        )
                    })
            }
        },
        removeRelation(product_id) {
            ApiService.delete(
                "product_page_meta/" +
                    this.product_page.id +
                    "/relation/" +
                    product_id
            )
                .then(() => {
                    const to_deleted_product = this.product_page.products.find(
                        (relation) => relation.product_id === product_id
                    )
                    this.products.push({
                        id: to_deleted_product.product_id,
                        name: to_deleted_product.product_name,
                    })
                    this.product_page.products =
                        this.product_page.products.filter(
                            (relation) => relation.product_id !== product_id
                        )
                })
                .catch(() => {
                    //
                })
        },
    },
}
</script>
